<!--商品详情-->
<template>
  <div class="page-good-detail">
    <Breadcrumb
      :data="routerList"
    ></Breadcrumb>

    <div class="common-gap"></div>

    <div class="info-content">
      <div class="project-table">
        <div class="project-name">Project Name：{{ projectList.name }}</div>
        <div class="to-order">Connected Orders:</div>
        <el-table
          ref="filterTable"
          :data="projectList.orders"
          style="width: 100%; border-spacing: 1px; background-color: #626262"
          border
          :header-row-style="{height:'61px'}"
        >
          <el-table-column
            prop="orderNo"
            label="Order No"
            max-width="241"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="Time"
            max-width="237"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="productName"
            label="Product Name"
            align="center"
            max-width="179"
          >
          </el-table-column>
          <el-table-column label="Qty/Price" align="center" max-width="213">
            <template slot-scope="scope">
              {{ scope.row.amount }}/{{ scope.row.price }}
            </template>
          </el-table-column>
          <el-table-column
            prop="contactEmail"
            label="Email"
            max-width="315"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="orderStatus"
            label="Status"
            max-width="378"
            align="center"
          >
            <template slot-scope="scope">
              <div
                :style="
                  scope.row.orderStatus === '进行中'
                    ? 'background: #FAB466;'
                    : 'background: #317FFF;'
                "
                class="tag"
              >
                {{ scope.row.orderStatus }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="projectName"
            label="Connected Projects"
            max-width="180"
            align="center"
          >
            <template>
              {{ projectList.name }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import { projectDetail } from '@/api/order'

export default {
  components: { Breadcrumb },
  data() {
    return {
      routerList: [],
      projectList: {
        name: '万达集团万达商',
        list: [
          {
            number: '1',
            time: '2023-02-02 15:15',
            goodsName: '油漆',
            count: '100',
            price: '222',
            email: '222@',
            state: '进行中',
            project: '万达',
          },
          {
            number: '1',
            time: '2023-02-02 15:15',
            goodsName: '油漆',
            count: '100',
            price: '222',
            email: '222@',
            state: '进行中',
            project: '万达',
          },
        ],
      },
    };
  },
  created() {
    this.routerList = [
      {
        name: 'My Order',
        to: {
          name: 'order'
        }
      },
      {
        name: 'Order Detail',
        to: {
          name: 'orderDetail',
          query: {
            id: this.$route.query.oid
          }
        }
      },
      {
        name: 'Project',
      }
    ]

    this.queryData()
  },
  methods: {
    async queryData() {
      const res = await projectDetail({
        // orderId: this.$route.query.oid,
        id: this.$route.query.pid,
      })

      this.projectList = res.data


    }
  }
};
</script>

<style lang="less" scoped>
.info-content {
  padding: 38px 80px 30px;

  .project-table {
    //height: 440px;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    box-shadow: 3px 3px 7px 0px rgba(198, 198, 198, 0.3);
    border-radius: 12px;
    padding: 34px 29px 46px 24px;

    .project-name {
      font-size: 22px;
      font-weight: 400;
      color: #333333;
    }

    .to-order {
      font-size: 22px;
      font-weight: 400;
      color: #333333;
      margin: 31px 0;
    }

    .tag {
      //width: 118px;
      padding: 0 10px;
      height: 56px;
      border-radius: 12px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    /deep/ .cell {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      font-weight: 400;
      color: #333333;
    }
  }
}
</style>